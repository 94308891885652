var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import * as React from 'react';
import copy from 'copy-to-clipboard';
const useCopyClick = _ref => {
  let {
    copyConfig,
    children
  } = _ref;
  const [copied, setCopied] = React.useState(false);
  const [copyLoading, setCopyLoading] = React.useState(false);
  const copyIdRef = React.useRef(null);
  const cleanCopyId = () => {
    if (copyIdRef.current) {
      clearTimeout(copyIdRef.current);
    }
  };
  const copyOptions = {};
  if (copyConfig.format) {
    copyOptions.format = copyConfig.format;
  }
  React.useEffect(() => cleanCopyId, []);
  return {
    copied,
    copyLoading,
    onClick: e => __awaiter(void 0, void 0, void 0, function* () {
      var _a;
      e === null || e === void 0 ? void 0 : e.preventDefault();
      e === null || e === void 0 ? void 0 : e.stopPropagation();
      setCopyLoading(true);
      try {
        const text = typeof copyConfig.text === 'function' ? yield copyConfig.text() : copyConfig.text;
        copy(text || String(children) || '', copyOptions);
        setCopyLoading(false);
        setCopied(true);
        // Trigger tips update
        cleanCopyId();
        copyIdRef.current = setTimeout(() => {
          setCopied(false);
        }, 3000);
        (_a = copyConfig.onCopy) === null || _a === void 0 ? void 0 : _a.call(copyConfig, e);
      } catch (error) {
        setCopyLoading(false);
        throw error;
      }
    })
  };
};
export default useCopyClick;