import { useEffect, useRef, useState } from 'react';
import { generateColor } from '../util';
function hasValue(value) {
  return value !== undefined;
}
const useColorState = (defaultStateValue, option) => {
  const {
    defaultValue,
    value
  } = option;
  const prevColor = useRef(generateColor(''));
  const [colorValue, _setColorValue] = useState(() => {
    let mergedState;
    if (hasValue(value)) {
      mergedState = value;
    } else if (hasValue(defaultValue)) {
      mergedState = defaultValue;
    } else {
      mergedState = defaultStateValue;
    }
    const color = generateColor(mergedState || '');
    prevColor.current = color;
    return color;
  });
  const setColorValue = color => {
    _setColorValue(color);
    prevColor.current = color;
  };
  useEffect(() => {
    if (hasValue(value)) {
      const newColor = generateColor(value || '');
      if (prevColor.current.cleared === true) {
        newColor.cleared = 'controlled';
      }
      setColorValue(newColor);
    }
  }, [value]);
  return [colorValue, setColorValue, prevColor];
};
export default useColorState;